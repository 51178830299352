import { Button, Col, Container, Link, PageBanner, Row, SocialButton, Stack, Thumbnail, Text } from '@zigurous/forge-react'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import React from 'react';
import { Page } from '../components';
import { trackEvent } from '../firebase/analytics';
import discordBanner from '../images/discord-banner.svg';
import type { FeaturedWorkData } from '../types';

interface HomeProps {
  data: {
    json: {
      featuredWork: FeaturedWorkData[];
    };
  };
  location: Location;
}

export default function Home({ data, location }: HomeProps) {
  const { featuredWork } = data.json;
  return (
    <Page className="home" location={location}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Text
                as="h1"
                className="text-pretty"
                marginBottom="lg"
                type="title-lg"
              >
                Game development assets and tutorials
              </Text>
              <Text className="text-pretty" marginBottom="lg" type="body-lg">
                We are an indie game developer creating Unity assets and
                tutorials to help new and experienced developers make games
                easier. Your support helps fund our own games alongside new
                assets and tutorials for the community.
              </Text>
              <Stack align="center" className="my-sm" spacing="md" wrap>
                <SocialButton
                  link="paypal"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Support Our Work',
                    });
                  }}
                  shape="pill"
                  size="md"
                  variant="outline"
                >
                  Support Our Work
                </SocialButton>
                <Link as={GatsbyLink} to="/assets" unstyled>
                  <Button
                    onClick={() => {
                      trackEvent('link', {
                        content_type: 'button',
                        event_category: 'engagement',
                        event_label: 'Download Assets',
                      });
                    }}
                    shape="pill"
                    size="md"
                    tabIndex={-1}
                    variant="outline"
                  >
                    Download Assets
                  </Button>
                </Link>
              </Stack>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="my-2xxl" fluid>
        <Row>
          {featuredWork.map((entry, index) => (
            <Col
              className="mb-lg"
              key={entry.identifier}
              size={12}
              md={6}
              lg={3}
            >
              <Thumbnail
                animated
                animation={['fade-in', 'slide-in']}
                aria-label={entry.title}
                as={GatsbyLink}
                image={{
                  alt: entry.title,
                  src: entry.image.sharp.original.src,
                  width: entry.image.sharp.original.width,
                  height: entry.image.sharp.original.height,
                }}
                index={index}
                onClick={() => {
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: entry.title,
                    item_id: entry.identifier,
                  });
                }}
                to={`/${entry.category}/${entry.identifier}`}
              />
              <Text bold marginTop="xxxs">
                {entry.title}
              </Text>
              <Text type="eyebrow">{entry.subtitle}</Text>
            </Col>
          ))}
        </Row>
      </Container>
      <PageBanner
        className="discord-banner"
        size="xl"
        style={{
          background: `url(${discordBanner}), linear-gradient(0deg,#7196ff -.18%,#2a5ee8 97.44%)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        theme="dark"
      >
        <Container fluid>
          <Row>
            <Col md={6} lg={5}>
              <Text as="div" className="ml-xxs" marginBottom="xxs" weight="500">
                Game Developers
              </Text>
              <Text type="title">Join our community!</Text>
              <Text className="text-pretty" marginBottom="lg" marginTop="lg">
                Our Discord server is a dedicated place to collaborate with
                other game developers, learn about making games, receive help
                and feedback, and much more! If you are looking for a community
                to grow alongside with, then this is a great place for you.
              </Text>
              <SocialButton
                className="my-sm"
                link="discord"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Join Discord',
                  });
                }}
                primaryColor="white"
                secondaryColor="#7289da"
                shape="pill"
                size="md"
                variant="outline"
              >
                Join Discord
              </SocialButton>
            </Col>
          </Row>
        </Container>
      </PageBanner>
    </Page>
  );
}

export const query = graphql`
  query FeaturedWork {
    json: allFeaturedWorkJson {
      featuredWork: nodes {
        category
        identifier
        title
        subtitle
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
